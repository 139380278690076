<template>
  <div class="settings">
    <div class="settings">
      <div class="container-fluid">
        <h1>Alerts</h1>
        <card>
          <div>
            <AlertTable :refresh="refresh" />
          </div>
        </card>
      </div>
    </div>
    <AlertData @change="handleChange" />
  </div>
</template>

<script>
import AlertTable from '@/components/Alert/AlertTable.vue';
import AlertData from '@/components/Alert/AlertData.vue';
import Card from '../components/Card/Card.vue';

export default {
  name: 'Alert',
  components: {
    Card,
    AlertTable,
    AlertData,
  },
  data() {
    return {
      refresh: null,
    };
  },
  methods: {
    handleChange() {
      this.refresh = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped></style>
