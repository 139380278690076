<template>
  <div class="property">
    <div class="title">
      {{ title }}
    </div>
    <div class="value link" @click="$emit('click')">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'TextProperty',
  props: {
    title: String,
    value: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.property {
  margin-top: 10px;

  .value {
    color: var(--secondary-font-color);

    &.monospace {
      font-size: 12px;
      font-family: monospace;
    }
  }

  .title {
    font-size: 10px;
    opacity: 0.8;
    text-transform: uppercase;
  }
}
</style>
