<template>
  <div v-if="alert" class="alert-background" @click.self="quit()">
    <div class="alert-data">
      <div class="close" @click="quit()">
        <i class="uil uil-times" />
      </div>
      <card title="Alert Information">
        <a class="link" @click="viewAlertSettings(alert.alert._id)">View alert configuration</a>
        <TextProperty title="Name" :value="alert.alert.name" />
        <LinkTextProperty
          @click="goToDevice(alert.device._id)"
          title="Device"
          :value="alert.device.name"
        />
        <TextProperty title="Description" :value="alert.alert.description || '-'" />
        <TextProperty
          title="Alerted"
          :value="
            $date(alert.createdAt).format('LLL') + ' (' + $date(alert.createdAt).fromNow() + ')'
          "
        />
        <div class="actions-row">
          <a class="active" v-if="!alert.muted" @click="mark(true)"> Mark read </a>
          <a class="active" v-else @click="mark(false)"> Mark unread </a>
          <a class="active" @click="resolve()"> Mark as Resolved </a>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import TextProperty from '../Property/TextProperty.vue';
import LinkTextProperty from '../Property/LinkTextProperty.vue';
import Card from '../Card/Card.vue';

export default {
  name: 'AlertData',
  data() {
    return {
      alert: null,
    };
  },
  components: {
    TextProperty,
    LinkTextProperty,
    Card,
  },
  watch: {
    '$route.query.view': {
      handler(v) {
        this.getAlert(v);
      },
    },
  },
  methods: {
    goToDevice(id, external = false) {
      if (external) {
        window.open(`/devices/approved/${id}`);
      } else {
        this.$router.push(`/devices/approved/${id}`);
      }
    },
    viewAlertSettings(id) {
      this.$router.push(`/alerts/manager/${id}`);
    },
    async getAlert(v) {
      if (v) {
        try {
          const response = await Utils.fetch(
            `/api/v1/alerts/triggers/get/${v}`,
            {},
            this,
          ).then((res) => res.json());
          if (response.success) {
            this.alert = response.alert;
          } else {
            this.$noty.warning(response.message);
          }
        } catch (e) {
          this.$noty.warning(e.toString());
          this.$router.push(this.$route.path);
        }
      } else {
        this.alert = null;
      }
    },
    quit() {
      this.$router.push(this.$route.path);
    },
    async mark(read) {
      await Utils.fetch(
        `/api/v1/alerts/triggers/${read ? '' : 'un'}mark`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alert._id,
          }),
        },
        this,
      )
        .then((_res) => _res.json())
        .catch((error) => {
          console.error(error.response);
        });

      this.$root.$emit('alertChange');
      this.$emit('change');
      this.quit();
    },
    async resolve() {
      await Utils.fetch(
        '/api/v1/alerts/triggers',
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alert._id,
          }),
        },
        this,
      )
        .then((_res) => _res.json())
        .catch((error) => {
          console.error(error.response);
        });
      this.$root.$emit('alertChange');
      this.$emit('change');
      this.quit();
    },
  },
  mounted() {
    this.getAlert(this.$route.query.view);
  },
};
</script>

<style scoped lang="scss">
.alert-background {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .alert-data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
  }
}

.close {
  position: absolute;
  left: 100%;
  margin-left: -28px;
  font-size: 22px;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.actions-row {
  padding: 10px 0;

  a {
    padding: 4px 8px;
    border: 1px solid white;
    color: var(--font-color);
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.3;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
