<template>
  <div class="command-table">
    <div class="actions-row">
      <base-button type="THEMED" :isSmall="true" v-on:click="mark(true)" title="Mark as Read" />
      <base-button type="THEMED" :isSmall="true" v-on:click="mark(false)" title="Mark as Unread" />
      <base-button type="THEMED" :isSmall="true" v-on:click="resolve()" title="Mark as Resolved" />
    </div>
    <table>
      <thead>
        <tr>
          <th>
            <CheckboxProperty v-model="checkbox.checkAll" />
          </th>
          <th>Device</th>
          <th>Title</th>
          <th>More Information</th>
          <th>Status</th>
          <th>Moment</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="alert in alerts" :key="alert._id" :class="{ marked: alert.marked }">
          <td>
            <CheckboxProperty v-model="alert.selected" />
          </td>
          <td>
            <a @click="goToDevice(alert.device._id)">{{ alert.device.name }}</a>
          </td>
          <td class="name">{{ alert.alert.name }}</td>
          <td><a @click="moreInfo(alert._id)">More Info</a></td>
          <td>
            <div v-if="alert.muted" class="read">Read</div>
            <div v-else class="unread">Unread</div>
          </td>
          <td>{{ $date(alert.createdAt).fromNow() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Utils from '@/utils';
import CheckboxProperty from '../Property/CheckboxProperty.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'AlertTable',
  components: {
    CheckboxProperty,
    BaseButton,
  },
  props: ['refresh'],
  watch: {
    alerts: {
      handler() {
        this.checkButtons();
      },
      deep: true,
    },
    'checkbox.checkAll': {
      handler(val) {
        this.alerts = this.alerts.map((e) => {
          e.selected = val;
          return e;
        });
      },
    },
    '$route.query.view': {
      handler(v) {
        if (v) {
          this.markRow(v);
        }
      },
    },
  },
  data() {
    return {
      checkbox: {
        checkAll: false,
      },
      alerts: [],
      buttons: {
        read: false,
        unread: false,
        resolve: false,
      },
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    checkButtons() {
      this.buttons.read = this.alerts.filter((e) => e.selected).some((e) => !e.muted);
      this.buttons.unread = this.alerts.filter((e) => e.selected).some((e) => e.muted);
      this.buttons.resolve = this.alerts.filter((e) => e.selected).length > 0;
    },
    async mark(read) {
      await Utils.fetch(
        `/api/v1/alerts/triggers/${read ? '' : 'un'}mark`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alerts.filter((e) => e.selected).map((e) => e._id),
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error.response);
        });

      this.getAlerts();
    },
    async resolve() {
      await Utils.fetch(
        '/api/v1/alerts/triggers',
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.alerts.filter((e) => e.selected).map((e) => e._id),
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error.response);
        });

      this.getAlerts();
    },
    markRow(id) {
      this.alerts = this.alerts.map((e) => {
        e.marked = e._id === id;
        return e;
      });
    },
    goToDevice(id) {
      this.$router.push(`/devices/approved/${id}`);
    },
    async getAlerts() {
      const response = await Utils.fetch('/api/v1/alerts/triggers', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.alerts = response.alerts.map((e) => {
          e.selected = false;
          e.marked = false;
          return e;
        });
      } else {
        this.$noty.warning(response.message);
      }
      this.checkbox.checkAll = false;
    },
    moreInfo(id) {
      this.$router.push(`${this.$route.path}?view=${id}`);
    },
  },
  mounted() {
    this.getAlerts().then((e) => {
      if (this.$route.query.view && this.$route.query.view !== '') {
        this.markRow(this.$route.query.view);
      }
    });

    this.$root.$on('alertChange', () => {
      this.getAlerts();
    });
  },
};
</script>

<style scoped lang="scss">
.command-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.command-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--accent-color);
    }

    &:hover {
      background: var(--accent-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

td a {
  text-decoration: underline;
}

td .unread {
  color: red;
}

.actions-row {
  padding: 10px 0;

  a {
    padding: 4px 8px;
    border: 1px solid white;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.3;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}

tr.marked {
  background: rgba(255, 255, 255, 0.1);
}
</style>
